exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-delivery-js": () => import("./../../../src/pages/about-delivery.js" /* webpackChunkName: "component---src-pages-about-delivery-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cancellation-policy-202310-js": () => import("./../../../src/pages/cancellation-policy_202310.js" /* webpackChunkName: "component---src-pages-cancellation-policy-202310-js" */),
  "component---src-pages-cancellation-policy-js": () => import("./../../../src/pages/cancellation-policy.js" /* webpackChunkName: "component---src-pages-cancellation-policy-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cotton-tote-breads-bag-js": () => import("./../../../src/pages/cotton-tote-breads-bag.js" /* webpackChunkName: "component---src-pages-cotton-tote-breads-bag-js" */),
  "component---src-pages-coverage-of-jrpass-js": () => import("./../../../src/pages/coverage-of-jrpass.js" /* webpackChunkName: "component---src-pages-coverage-of-jrpass-js" */),
  "component---src-pages-eligibility-for-use-js": () => import("./../../../src/pages/eligibility-for-use.js" /* webpackChunkName: "component---src-pages-eligibility-for-use-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspirations-13-vital-qanda-for-your-japan-trip-js": () => import("./../../../src/pages/inspirations/13-vital-qanda-for-your-japan-trip.js" /* webpackChunkName: "component---src-pages-inspirations-13-vital-qanda-for-your-japan-trip-js" */),
  "component---src-pages-inspirations-exploring-japan-with-jrpass-js": () => import("./../../../src/pages/inspirations/exploring-japan-with-jrpass.js" /* webpackChunkName: "component---src-pages-inspirations-exploring-japan-with-jrpass-js" */),
  "component---src-pages-inspirations-how-to-hold-chopsticks-js": () => import("./../../../src/pages/inspirations/how-to-hold-chopsticks.js" /* webpackChunkName: "component---src-pages-inspirations-how-to-hold-chopsticks-js" */),
  "component---src-pages-inspirations-izakaya-js": () => import("./../../../src/pages/inspirations/izakaya.js" /* webpackChunkName: "component---src-pages-inspirations-izakaya-js" */),
  "component---src-pages-inspirations-js": () => import("./../../../src/pages/inspirations.js" /* webpackChunkName: "component---src-pages-inspirations-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-price-revisions-js": () => import("./../../../src/pages/price-revisions.js" /* webpackChunkName: "component---src-pages-price-revisions-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-what-is-jrpass-js": () => import("./../../../src/pages/what-is-jrpass.js" /* webpackChunkName: "component---src-pages-what-is-jrpass-js" */),
  "component---src-pages-which-japan-rail-pass-shall-i-get-js": () => import("./../../../src/pages/which-japan-rail-pass-shall-i-get.js" /* webpackChunkName: "component---src-pages-which-japan-rail-pass-shall-i-get-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */)
}

