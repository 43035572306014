module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/jtc-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3387307de6933522f68f50024bf30ce8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-shopping-cart/gatsby-browser.js'),
      options: {"plugins":[],"mode":"payment","cartMode":"client-only","stripePublicKey":"pk_live_51NQYWoGox1ipRA3u8WtkfroG2PvvoGfC6ncTeHdbeA32WKXcyZybB4A30Gkr0opdBXTLTWpCuQ58x8IjwyCYtwMs00spaAfAw0","successUrl":"https://japan-railpass.co.uk//thanks","cancelUrl":"https://japan-railpass.co.uk//form","currency":"GBP","billingAddressCollection":false,"allowedCountries":["US","GB","CA"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T8MMF3K","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-T8MMF3K","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer","anonymize":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
